@import 'autoload';

.slideshow {
  @media #{$smartphone} {
    display: none;
  }
}

.store {
  position: relative;

  ul.owl-carousel {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    position: inherit;

    .owl-item {
      margin-right: 15px;
    }

    &:hover li {
      opacity: .75;
    }

    li {
      align-items: flex-end;
      border: 1px solid $grey-border;
      border-radius: 5px;
      display: flex;
      transition-duration: $transition-duration;

      &:hover {
        opacity: 1;
      }

      a {
        align-items: flex-end;
        display: flex;
        flex-grow: 1;

        img {
          border-radius: 4px;
          width: 100%;
        }
      }
    }

    .owl-buttons {
      .owl-prev,
      .owl-next {
        align-items: center;
        background: $grey;
        border-radius: 50%;
        color: $white;
        display: flex;
        font-size: 1.2rem;
        height: 25px;
        justify-content: center;
        position: absolute;
        transition-duration: $transition-duration;
        width: 25px;

        &:hover {
          background-color: $green;
        }
      }

      .owl-prev {
        right: 30px;
        top: 0;

        @media #{$smartphone} {
          top: 10px;
        }
      }

      .owl-next {
        right: 0;
        top: 0;

        @media #{$smartphone} {
          top: 10px;
        }
      }
    }
  }
}

.banners ul {
  display: grid;
  grid-gap: 30px;
  text-align: center;

  @media #{$desktop} {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  @media #{$tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$smartphone} {
    grid-template-columns: 1fr;
  }
}
